/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "/src/assets/styles/variables.scss";
@import "primeng/resources/primeng.css";
@import "/node_modules/ngx-spinner/animations/ball-clip-rotate.css";

* {
  font-family: "Roboto_regular", Arial, sans-serif;
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  background: $white-color;
  overflow: hidden;
  height: inherit;
}

app-header {
  display: block;
}

.text-white {
  color: $white-color;
}

.cursor-pointer {
  cursor: pointer;
}

.font-lg {
  font-size: 1.5rem;
}

.font-xs {
  font-size: 0.7rem;
}

h1 {
  color: $primary-color;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

.forms-main-container {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.lead-form-main-container,
.forms-main-container {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 50px;

  .headerImg {
    height: 66px;
    width: auto;
  }

  input[type="radio"],
  input[type="checkbox"] {
    border-color: grey;
  }

  .label-color-primary {
    color: $primary-color;
  }

  .form-label {
    color: $heading-dark-color;
  }

  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .btn-style:hover {
    background-color: $primary-color;
    color: $white-color;
  }

  .btn-style {
    border-radius: 20px;
    padding: 0 15px;
    color: $primary-color;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    display: flex;
    align-items: center;
    margin-left: 12px;
    height: 40px;
    cursor: pointer;
    border: solid 1px $primary-color;
    text-decoration: none;

    &.disabled {
      background: $disabled-bg-color;
      @extend %font-style-1;
      color: $disabled-color;
      border-color: $disabled-border-color;

      &:hover {
        background: $disabled-bg-color;
        color: $disabled-color;
        border-color: $disabled-border-color;
      }
    }
  }

  .invalid-feedback {
    font-size: 13px;

    &.textarea-errors {
      top: -35px;
      position: relative;
    }
  }

  .form-preview {
    margin-top: -30px;

    .container {
      border: 1px dashed #0f1362;
      border-radius: 12px;
      margin-top: 50px;

      .preview-cont {
        .label-text {
          font-weight: 600;

          :before {
            content: "\27A4 ";
          }
        }

        .preview-media {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .template-content {
          p {
            @extend %font-style-3;
            color: $heading-dark-color;
            text-align: left;
            word-wrap: break-word;
            max-height: 350px;
            overflow: hidden;
            white-space: pre-line;
            padding: 2px;
          }

          .preview-media {
            margin: 10px;
            width: 151px;
            height: auto;
            padding: 2px;
          }
        }

        form {
          width: 90%;
          margin: auto;

          .highlight-circle {
            transform: scale(1.25);
            margin: 10px 7px !important;
          }

          .rating-circle {
            margin: 10px 2px;
            height: 37px;
            width: 37px;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            padding: 5px;
            text-align: center;
            color: white;
          }

          .rating-circle-bad {
            background-color: $red-color;
          }

          .rating-circle-average {
            background-color: $yellow-color;
          }

          .rating-circle-good {
            background-color: $green-color;
          }

          .form-check-inline {
            input,
            label {
              cursor: pointer;
            }
          }

          .radio-btn-error {
            color: #dc3545;
          }

          table {
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            thead {
              tr {
                background-color: #2d4393;
                color: white;
                text-align: center;

                th {
                  border: 1px solid black;
                }
              }
            }

            tbody {
              tr {
                text-align: center;

                th {
                  border: 1px solid;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
  .submit-container {
    min-height: 80%;
  }
  .submit-container-no-logo {
    min-height: 100%;
  }
}

/* ng-select custom css */
ng-select {
  &.is-invalid {
    .ng-select-container {
      border-color: #dc3545;
    }
  }

  &.table-dropdown {
    .ng-select-container {
      min-height: 32px !important;
    }
  }

  .ng-select-container {
    min-height: 48px !important;
    border-radius: 2px !important;

    .ng-value-container {
      .ng-placeholder {
        top: 10px !important;
      }

      .ng-input > input {
        height: 36px;
      }

      .ng-value-disabled {
        color: $primary-gray-headers;
        opacity: 0.7;
      }
    }
  }

  ng-dropdown-panel {
    .ng-option input {
      margin-right: 5px;
    }

    .ng-option span {
      float: left;
    }
  }
}

.ql-editor {
  padding: 0px;
  height: auto;
}

/*Media Query*/
@media only screen and (max-device-width: 992px) {
  .variant_dropdown,
  .state_dropdown,
  .city_dropdown,
  .dealer_dropdown {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      white-space: normal;
    }
  }
}
